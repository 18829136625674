<template>
  <div style="min-height: 750px;" v-if="d_userExternalapplicationWdm">
    <b-row>
      <b-col sm="12" lg="2"></b-col>
      <b-col sm="12" lg="8"></b-col>
      <b-col sm="12" lg="2"></b-col>
    </b-row>
    <b-row style="margin-bottom: 20px; border-bottom: solid 1px whitesmoke;">
      <b-col sm="12" md="2" lg="2"> </b-col>
      <b-col sm="12" md="4" lg="4">
        <i class="fa fa-desktop"></i>
        {{ StoreLangTranslation.data['external_application_record'][StoreLang] }}
      </b-col>
      <b-col sm="12" md="4" lg="4" :style="StoreDevice.isMobile ? '' : 'text-align: right;'"></b-col>
      <b-col sm="12" md="2" lg="2"></b-col>
    </b-row>
    <b-row>
      <b-col sm="12" lg="2"></b-col>
      <b-col sm="12" lg="8">
        <b-row style="margin: 3px;">
          <b-col sm="12" lg="4">
            {{ d_userExternalapplicationWdm['0']['parameters']['0']['name']['translation'][StoreLang] }}
          </b-col>
          <b-col sm="12" lg="8">
            <select class="form-control" v-model="d_userExternalapplicationData.status">
              <option v-for="(op, op_index) in d_userExternalapplicationWdm['0']['parameters']['0'].options" :value="op.value">
                {{ op.translation[StoreLang] }}
              </option>
            </select>
          </b-col>
        </b-row>
        <b-row style="margin: 3px;" v-if="d_userExternalapplicationData.key">
          <b-col sm="12" lg="4">
            {{ d_userExternalapplicationWdm['0']['parameters']['1']['name']['translation'][StoreLang] }}
          </b-col>
          <b-col sm="12" lg="8">
            {{ d_userExternalapplicationData.key }}
            <!--
              <span @click="f_generateRandomKey()" style="padding-left: 15px; cursor: pointer; text-decoration: underline; font-style: italic;">
              {{ StoreLangTranslation.data['key_create'][StoreLang] }}
              </span>
              <b-form-input type="text" class="form-control" v-model="d_userExternalapplicationData.key" :placeholder="StoreLangTranslation.data['please_fill'][StoreLang]"></b-form-input>
            -->
          </b-col>
        </b-row>
        <b-row style="margin: 3px;">
          <b-col sm="12" lg="4">
            {{ d_userExternalapplicationWdm['0']['parameters']['2']['name']['translation'][StoreLang] }}
          </b-col>
          <b-col sm="12" lg="8">
            <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
              <b-col sm="12" lg="4">
                {{ lang.label }}
              </b-col>
              <b-col sm="12" lg="8">
                <input type="text" class="form-control" v-model="d_userExternalapplicationData.url[lang.label]">
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row style="margin: 3px;">
          <b-col sm="12" lg="4">
            {{ d_userExternalapplicationWdm['0']['parameters']['3']['name']['translation'][StoreLang] }}
          </b-col>
          <b-col sm="12" lg="8">
            <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
              <b-col sm="12" lg="4">
                {{ lang.label }}
              </b-col>
              <b-col sm="12" lg="8">
                <input type="text" class="form-control" v-model="d_userExternalapplicationData.route[lang.label]">
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row style="margin: 3px;">
          <b-col sm="12" lg="6">
          </b-col>
          <b-col sm="12" lg="6">
            <b-button variant="secondary" @click="f_userExternalapplicationRecord()">
              {{ StoreLangTranslation.data['save'][StoreLang] }}
            </b-button>
          </b-col>
        </b-row>
      </b-col>
      <b-col sm="12" lg="2"></b-col>
    </b-row>
  </div>
</template>

<script>
import { ClsWdm } from '@/wam/modules/wdm';
import store from '@/store';
import { ServiceFunctions } from '@/wam/modules/service';
import { mapGetters } from 'vuex';
export default {
  name: 'user_externalapplication_record',
  computed: mapGetters({
    StoreLang: 'StoreLang',
    StoreLangList: 'StoreLangList',
    StoreLangTranslation: 'StoreLangTranslation',
    StoreFrontendSettings: 'StoreFrontendSettings',
    StoreDevice: 'StoreDevice',
  }),
  components: {},
  props: {},
  data () {
    return {
      d_user: '',
      d_frontendClientID: '',
      d_userExternalapplicationClientID: '',
      d_frontendList: [],
      d_userExternalapplicationWdm: '',
      d_userExternalapplicationData: {
        'status': '0',
        'key': '',
        'url': {},
        'route': {},
      }
    };
  },
  created: function () {
    ClsWdm.getLayerWdm('usr_extky').then(resp_wdm_data => { this.d_userExternalapplicationWdm = resp_wdm_data; });
    this.d_user = JSON.parse(localStorage.getItem('user'));
    this.f_langEligibleParameterProcess();
  },
  beforeMount () {},
  mounted: function () {
    if (this.$route.query.user_externalapplication_client_id) {
      this.d_userExternalapplicationClientID = this.$route.query.user_externalapplication_client_id;
      this.f_userExternalapplicationGet();
    }
    // console.log(this.StoreLangList);
    // console.log(this.StoreFrontendSettings);
    // console.log(this.d_userExternalapplicationWdm);
  },
  methods: {
    f_generateRandomKey: function () {
      // 689312d9-0258-4359-8d88-fafbfd253251
      let part_count_list = [8,4,4,4,12];
      let token_key = '';
      for (let k in part_count_list) {
        let length = part_count_list[k];
          // charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ",
        let charset = "a111c223de11fghij0klmn444o22pq33rst33u77vwx9yz0123456789";
        let retVal = "";
        for (var i = 0, n = charset.length; i < length; ++i) {
          retVal += charset.charAt(Math.floor(Math.random() * n));
        }
        /*
          if (token_key) {
            token_key += '-';
          }
        */
        token_key += retVal;
      }
      this.d_userExternalapplicationData.key = token_key;
    },
    f_langEligibleParameterProcess: function () {
      let lang_eligible_variables = ['url', 'route'];
      for (let k in lang_eligible_variables) {
        for (let lang_index in this.StoreLangList.data) {
          if (!this.d_userExternalapplicationData[lang_eligible_variables[k]][this.StoreLangList.data[lang_index].label]) {
            this.d_userExternalapplicationData[lang_eligible_variables[k]][this.StoreLangList.data[lang_index].label] = '';
          }
        }
      }
    },
    f_userExternalapplicationRecord: function () {
      let query = 'user_client_id=' + this.d_user.username;
      let data = {};
      if (this.d_userExternalapplicationClientID) {
        query += '&user_externalapplication_client_id=' + this.d_userExternalapplicationClientID;
      }
      for (let i in this.d_userExternalapplicationData) {
        data[i] = this.d_userExternalapplicationData[i];
      }
      ServiceFunctions.user_externalapplication_record(query, data)
        .then(resp => {
          if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
            alert(this.StoreLangTranslation.data['process_completed'][this.StoreLang]);
          } else {
            alert(resp.data.status_code + ' = ' + resp.data.status_message);
          }
        });
    },
    f_userExternalapplicationGet: function () {
      let query = 'user_externalapplication_client_id=' + this.d_userExternalapplicationClientID;
      query += '&data_type=value_mode';
      let data = {};
      ServiceFunctions.user_externalapplication_get(query, data)
        .then(resp => {
          if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
            if (resp.data.data) {
              this.d_userExternalapplicationData = resp.data.data;
              this.f_langEligibleParameterProcess();
            } else {
              alert(this.StoreLangTranslation.data['process_completed'][this.StoreLang]);
            }
          } else {
            alert(resp.data.status_code + ' = ' + resp.data.status_message);
          }
        });
    },
  }
};

</script>

